// Second level menu
$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
  }
  var $subMenu = $(this).next(".dropdown-menu");
  $subMenu.toggleClass('show');

  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    $('.dropdown-submenu .show').removeClass("show");
  });

  return false;
});

// Initiate baguetteBox lightbox
baguetteBox.run('.gallery', {
  noScrollbars: true
});

// Initiate tooltipster
$('.hotspot').tooltipster({
  animation: 'fade',
  delay: 200,
  theme: ['tooltipster-shadow', 'shadow-custom'],
  interactive: true,
  contentCloning: true,
  trigger: 'custom',
  triggerOpen: {
    mouseenter: true,
    touchstart: true,
    click: true,
    tap: true
  },
  triggerClose: {
    mouseleave: true,
    originClick: true,
    touchleave: true,
    click: true,
    tap: true
  }
});